<template>
  <!-- JetLinks性能表现 -->
  <div class="platform">
    <jet-card title="JetLinks性能表现" subTitle="JetLinks支持私有化或容器化以单机、集群或微服务的方式部署在任意平台服务器上，支持横向拓展，具有极佳的性能表现。">
      <ul class="card-list">
        <li class="card-list-item">
          <p class="first">设备连接</p>
          <h4 class="title">千万级</h4>
          <p class="content">架构水平扩容，支持千万级设备连接。</p>
        </li>
        <li class="card-list-item">
          <p class="first">数据并发</p>
          <h4 class="title">百万级</h4>
          <p class="content">架构水平扩容，支持百万级数据并发。</p>
        </li>
        <li class="card-list-item">
          <p class="first">任务调度处理</p>
          <h4 class="title">10w+</h4>
          <p class="content">自研RactorQL实现规则引擎任务处理，具有极佳的处理性能。</p>
        </li>
        <li class="card-list-item">
          <p class="first">设备延迟</p>
          <h4 class="title">＜30ms</h4>
          <p class="content">全响应式非阻塞网络编程，具有极低的设备延迟。</p>
        </li>
      </ul>
    </jet-card>
  </div>
</template>

<script>
import JetCard from '@/components/common/JetCard';
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    JetCard
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  
  .card-list{
    width: 75rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card-list-item {
      width: 49%;
      margin: 1% 0;
      background-color: #FFFFFF;
      box-sizing: border-box;
      border: 1px solid rgba(30, 60, 96, 0);
      color: #000000;
      padding: 2.25rem;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      transition: all .2s linear;
      &:hover{
        transform: scale(1.02);
      }
      .first{
        color: rgba(14, 110, 255, 1) !important;
        font-size: 1.5rem;
        font-weight: 600;
      }
      .symbol {
        margin: 0 auto;
      }
      .title {
        font-weight: 700;
        text-align: left;
        display: block;
        font-size: 2.5rem;
        color: #061437;
      }
      .content {
        line-height: 36px;
        font-size: 1.5rem;
        letter-spacing: 2px;
        margin: 0;
        color: rgba(6, 20, 55, 0.8);
      }
    }
  }
</style>