<template>
  <div class="meun">
    <ul>
      <li :class="{active: currentIndex == 0}">
        <router-link to="/"><span class="menu-title">首页</span></router-link>
      </li>
      <li :class="{active: currentIndex == 1}">
        <span class="menu-title" @mouseover="change = true">产品</span>
        <div class="sub-menu" v-show="change">
          <a-row>
            <a-col :span="4">
              <div class="sub-menu-left">
                <img src="@/assets/images/menu/product.png">
                <div style="margin-left: 2rem;">
                  <h3>产品</h3>
                  <p>JetLinks-iot</p>
                  <p v-if="false">JetLinks-edge</p>
                  <p>JetLinks-view</p>
                </div>
              </div>
            </a-col>
            <a-col :span="20">
              <div class="sub-menu-right">
                <p>
                  <span>物联网平台：</span>
                  <router-link to="/production/iot"><span>JetLinks iot →  </span></router-link>
                </p>
                <p v-if="false">
                  <span>物联网边缘网关：</span>
                  <router-link to="/production/edge"><span>JetLinks edge →  </span></router-link>
                </p>
                <p>
                  <span>物联网可视化平台：</span>
                  <router-link to="/production/view"><span>JetLinks-view →  </span></router-link>
                </p>
              </div>
            </a-col>
          </a-row>
        </div>
      </li>
      <li :class="{active: currentIndex == 2}" v-if="false">
        <span class="menu-title">解决方案</span>
        <div class="sub-menu">
          <a-row>
            <a-col :span="4">
              <div class="sub-menu-left">
                <img src="@/assets/images/menu/solution.png">
                <div style="margin-top: 1rem;">
                  <h3>解决方案</h3>
                  <p>JetLinks 针对不同得行业提供定制化得解决方案</p>
                  <span style="color: #0E6EFF;margin-top: 1.5rem;display: inline-block">咨询电话：00000000</span>
                </div>
              </div>
            </a-col>
            <a-col :span="20">
              <div class="sub-menu-right">
                <a-row :gutter="40">
                  <a-col :span="6" :class="{active: $route.path == '/solution/industry'}">
                    <router-link to="/solution/industry">
                      <h3>智慧工业</h3>
                      <p>从提高生产效率、实现节能减排和完成产业结构调整出发……</p>
                    </router-link>
                  </a-col>
                  <a-col :span="6" :class="{active: $route.path == '/solution/city'}">
                    <router-link to="/solution/city">
                      <h3>智慧城市</h3>
                      <p>利用物联网、云计算、大数据、人工智能、可视化等技术构建……</p>
                    </router-link>
                  </a-col>
                  <a-col :span="6" :class="{active: $route.path == '/solution/construction'}">
                    <router-link to="/solution/construction">
                      <h3>智慧工地</h3>
                      <p>提升工程质量、施工安全、节约成本，提高施工现场决策能力和……</p>
                    </router-link>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </div>
      </li>
      <li :class="{active: currentIndex == 3}">
        <a href="http://doc.jetlinks.cn" target="_blank"><span class="menu-title">文档</span></a>
      </li>
      <li :class="{active: currentIndex == 4}">
        <router-link to="/company"><span class="menu-title">公司</span></router-link>
      </li>
    </ul>
    <!-- <a-menu v-model="current" :mode="mode">
      <a-menu-item key="/"><router-link to="/"><span class="menu-title">首页</span></router-link></a-menu-item>
      <a-menu-item key="/production">产品</a-menu-item>
      <a-sub-menu>
        <span slot="title" class="submenu-title-wrapper"><span class="menu-title">产品</span><span class="drop-down"></span></span>
        <a-menu-item key="/production/jetlinks_iot">
          <router-link to="/production/jetlinks_iot">物联网平台</router-link>
        </a-menu-item>
        <a-menu-item key="/production/video_access_display">
          <router-link to="/production/video_access_display">视频接入展示</router-link>
        </a-menu-item>
        <a-menu-item key="/solution/edge">
          <router-link to="/solution/edge">物联网边缘网关</router-link>
        </a-menu-item>
      </a-sub-menu> 
      <a-sub-menu>
        <span slot="title" class="submenu-title-wrapper"><span class="menu-title">解决方案</span><span class="drop-down"></span></span>
        <a-menu-item key="/solution/industry">
          <router-link to="/solution/industry">智慧工业</router-link>
        </a-menu-item>
        <a-menu-item key="/solution/city">
          <router-link to="/solution/city">智慧城市</router-link>
        </a-menu-item>
        <a-menu-item key="/solution/construction">
          <router-link to="/solution/construction">智慧工地</router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item key="/commercial-license"><router-link to="/commercial-license"><span class="menu-title">商业授权</span></router-link></a-menu-item> 
      <a-menu-item key="/document"><router-link to="/document"><span class="menu-title">文档中心</span></router-link></a-menu-item>
      <a-sub-menu>
        <span slot="title" class="submenu-title-wrapper"><span class="menu-title">拓展功能</span><span class="drop-down"></span></span>
        <a-menu-item key="/application">
          <router-link to="/application">
            应用组件
          </router-link>
        </a-menu-item>
        <a-menu-item key="/protocol">
          <router-link to="/protocol">
            协议组件
          </router-link>
        </a-menu-item>
      </a-sub-menu> 
      <a-menu-item key="/news/list">
        <router-link to="/news/list">
          <span class="menu-title">新闻资讯</span>
        </router-link>
      </a-menu-item> 
      <a-sub-menu>
        <span slot="title" class="submenu-title-wrapper"><span class="menu-title">公司概况</span><span class="drop-down"></span></span>
        <a-menu-item key="setting:7">
          <router-link to="/about">
            关于我们
          </router-link>
        </a-menu-item>
        <a-menu-item key="/contact">
          <router-link to="/contact">
            联系我们
          </router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>   -->
  </div>
</template>

<script>
export default {
  name: 'menus',
  data() {
    return {
       currentIndex: 0,
       change: true
    }
  },
  components: {
    
  },
  beforeMount() {

  },
  methods: {
    
  },
  computed: {
    path() {
      return this.$route.path
    }
  },
  watch: {
    path(newVal) {
      this.change = false;
      switch(true) {
        case newVal == '/':
          this.currentIndex = 0;
        break;
        case newVal.indexOf('/production') != -1:
          this.currentIndex = 1;
        break
        case newVal.indexOf('/solution') != -1:
          this.currentIndex = 2;
        break
        case newVal.indexOf('/document') != -1:
          this.currentIndex = 3;
        break
        case newVal.indexOf('/company') != -1:
          this.currentIndex = 4;
        break
      }
    }
  }
}
</script>
<style scoped lang="less">
  .meun{
    width: 70%;
    height: 100%;
    padding: 0 5rem;
    ul {
      display: flex;
      height: 100%;
      align-items: center;
      margin: 0;
      li {
        box-sizing: border-box;
        height: 100%;
        .menu-title{
          display: inline-block;
          width: 100%;
          line-height: 6.25rem;
          padding: 0 2rem;
          cursor: pointer;
        }
        white-space: nowrap;
        &.active{
          .menu-title{
            color: #0E6EFF !important;
            position: relative;
            &::after{
              content: '';
              width: 90%;
              height: 2px;
              position: absolute;
              left: 5%;
              bottom: 1.5rem;
              background-color: #0E6EFF;
            }
          }
        }
        &:hover{
          .menu-title{
            position: relative;
            &::after{
              content: '';
              width: 90%;
              height: 2px;
              position: absolute;
              left: 5%;
              bottom: 1.5rem;
              background-color: #0E6EFF;
            }
          }
          .sub-menu{
            width: 100vw;
            height: 20rem;
          }
        }
        .sub-menu{
          width: 100vw;
          min-width: 1400px;
          height: 0;
          overflow: hidden;
          position: fixed;
          background-color: #FCFCFC;
          left: 0;
          top: 6.25rem;
          box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
          transition: height .2s linear;
          .sub-menu-left{
            width: 100%;
            height: 100%;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            padding: 2rem 3.75rem;
          }
          .sub-menu-right{
            padding: 2rem 2rem 0 2rem;
            p{
              margin: 2.5rem 0;
              & span:first-child{
                font-size: 1.25rem;
              }
              & span:last-child{
                cursor: pointer;
                font-size: 1.5rem;
                color: #0E6EFF;
              }
            }
            .ant-col{
              cursor: pointer;
              height: 100%;
              padding-left: 20px;
              padding-right: 20px;
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              justify-content: center;
              a{
                color: #000000;
                &:hover{
                  color: #000000;
                }
              }
              &.active{
                h3{
                  color: #0E6EFF;
                }
              }
              &:hover{
                h3{
                  color: #0E6EFF
                }
              }
            }
          }
        }
      }
    }
  }
  a{
    color: #000000;
    &:hover{
      color: #000000;
    }
  }
</style>