import { render, staticRenderFns } from "./JetCard.vue?vue&type=template&id=0af6bdbf&scoped=true&"
import script from "./JetCard.vue?vue&type=script&lang=js&"
export * from "./JetCard.vue?vue&type=script&lang=js&"
import style0 from "./JetCard.vue?vue&type=style&index=0&id=0af6bdbf&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0af6bdbf",
  null
  
)

export default component.exports